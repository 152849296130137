import axios from "./http-interceptoer";
export default {
  /**
   * 根据食堂ID获取，食堂食堂运行时间数据
   * @param {*} id
   * @returns
   */
  getRealTimeDataForCanteen(canteenId) {
    return axios.get("/api/client-manage/screen/dataForCanteen/" + canteenId);
  },
  /**
   * 根据食堂ID获取，获取食堂的经营证照
   * @param {*} canteenId
   * @returns
   */
  getCanteenPic(canteenId) {
    return axios.get("/api/client-manage/screen/getCanteenPic/" + canteenId);
  },
  /**
   * 根据食堂ID，获取员工健康证
   * @param {*} canteenId
   * @returns
   */
  getUserHealthCardPic(canteenId) {
    return axios.get(
      "/api/client-manage/screen/getUserHealthCardPic/" + canteenId
    );
  },
  /**
   * 根据食堂ID,获取当天的所有类型告警信息
   * @param {*} canteenId
   * @returns
   */
  getCanteenAlarmBatch(canteenId) {
    return axios.get(
      "/api/client-manage/screen/getCanteenAlarmBatch/" + canteenId
    );
  },
  /**
   * 根据食堂ID,获取食堂温湿度设备数组信息
   * @param {*} canteenId
   * @returns
   */
  getTemperatureAndHumidityRecord(canteenId) {
    return axios.get(
      "/api/client-manage/screen/getTemperatureAndHumidityRecord/" + canteenId
    );
  },
  /**
   * 根据食堂ID,获取食堂人员信息
   * @param {*} canteenId
   * @returns
   */
  getCanteenPeopleInfo(canteenId) {
    return axios.get(
      "/api/client-manage/screen/listTodayStaffStatus/" + canteenId
    );
  },
  getCanteenDetail(id) {
    return axios.get("/api/client-manage/canteen/get/" + id);
  },
  getSampleInfo(id) {
    return axios.get(
      "/api/client-manage/clientSampleRecord/getSampleInfo/" + id
    );
  }
};
