/**
 * 任务管理器 by Aaron
 * 定时执行任务，并监督任务是否出现异常。
 * 对异常的任务自动重启。
 */
export default class InterlvalManager {
  /**
   * 属性私有化-阻止直接修改任务类型（废弃）
   * 有插件导致私有变量不认识
   *
   */
  // _interlvalName = "无名定时任务管理器"; // 管理器名称，用于日志
  // _taskList = []; // 任务列表

  /**
   * 定时任务管理器名称，只允许是string.
   * 建议传入页面的名字，用于日志打印标记。
   * @param {*} interlvalName
   */
  constructor(interlvalName) {
    this._interlvalName = '无名定时任务管理器' // 管理器名称，用于日志
    this._taskList = [] // 任务列表
    if (interlvalName && typeof interlvalName === 'string') {
      this._interlvalName = interlvalName
    }
    /**
     * 启动监督程序
     */
    this.supervisorExe()
  }

  /** 添加任务
   *  注意：任务的描述，是任务的唯一字符串标识，如果任务队列存在同名任务，后来的任务将替换之前的任务。
   * @param {*} task:function | 任务函数
   * @param {*} sleepTime:number | 执行间隔
   * @param {*} taskDescribe:string | 任务描述，uuid
   * @returns 任务索引位置
   */
  addTask(task, sleepTime, taskDescribe) {
    /**
     * 参数检查
     */
    if (typeof task != 'function') {
      console.log('-----------------定时任务管理器日志------------------')
      console.log(this._interlvalName + ':添加定时任务失败')
      console.log('第一个参数需要function类型，你传入的是' + typeof task + '类型')
      return
    }
    if (typeof sleepTime != 'number' || parseInt(sleepTime) < 1) {
      console.log('-----------------定时任务管理器日志------------------')
      console.log(this._interlvalName + ':添加定时任务失败')
      console.log('第二参数需要number类型正数，你传入的是' + typeof sleepTime + ':' + sleepTime)
      return
    }
    if (typeof taskDescribe != 'string' || taskDescribe.length < 1) {
      console.log('-----------------定时任务管理器日志------------------')
      console.log(this._interlvalName + ':添加定时任务失败')
      console.log(
        '第三参数需要string类型非空值，你传入的是' + typeof taskDescribe + ':' + taskDescribe
      )
      return
    }

    /**
     * 同名任务检查替换
     */
    let selectedIndex = -1
    this._taskList.find((item, index) => {
      if (item.taskDescribe == taskDescribe) {
        selectedIndex = index
        return true
      }
      return false
    })

    /**
     * 替换，创建并启动
     */
    if (selectedIndex < 0) {
      this._taskList.push(new InterlvalTask(task, sleepTime, taskDescribe))
      return this._taskList.length - 1
    } else {
      this._taskList[selectedIndex].sleepTime = sleepTime
      this._taskList[selectedIndex].task = task
      return selectedIndex
    }
  }

  /**
   * 获取索引位置的任务状态。
   * @param {*} taskIndex
   * @returns
   */
  showTaskStateByIndex(taskIndex) {
    if (taskIndex < 0 || taskIndex >= this._taskList.length) {
      console.log('-----------------定时任务管理器日志------------------')
      console.log('获取任务状态信息失败')
      console.log('索引值：' + taskIndex + '不在任务列表范围内')
      return ''
    }
    this._taskList[taskIndex].showMessage()
  }
  /**
   * 防重复开启任务
   * @param {*} taskIndex
   * @returns
   */
  startTaskByIndex(taskIndex) {
    if (taskIndex < 0 || taskIndex >= this._taskList.length) {
      console.log('-----------------定时任务管理器日志------------------')
      console.log('开启任务失败')
      console.log('索引值：' + taskIndex + '不在任务列表范围内')
      return false
    }
    this._taskList[taskIndex].start()
    return true
  }
  /**
   * 停止索引位置的任务。
   * @param {*} taskIndex
   * @returns
   */
  stopTaskByIndex(taskIndex) {
    if (taskIndex < 0 || taskIndex >= this._taskList.length) {
      console.log('-----------------定时任务管理器日志------------------')
      console.log('停止任务失败')
      console.log('索引值：' + taskIndex + '不在任务列表范围内')
      return false
    }
    this._taskList[taskIndex].stop()
    return true
  }
  /**
   * 重新启动索引位置的任务。
   * @param {*} taskIndex
   * @returns
   */
  restartTaskByIndex(taskIndex) {
    if (taskIndex < 0 || taskIndex >= this._taskList.length) {
      console.log('-----------------定时任务管理器日志------------------')
      console.log('重启任务失败')
      console.log('索引值：' + taskIndex + '不在任务列表范围内')
      return false
    }
    this._taskList[taskIndex].restart()
    return true
  }

  /**
   * 自检程序-检查所有激活但是超时未执行的程序并重新激活
   * 为了防止监督者与其他任务冲突，应该给他随机时间
   */
  supervisorExe() {
    /**
     * 120~240 秒
     */
    let time = 120 + parseInt(Math.random() * 120)
    let thisObj = this
    setTimeout(function() {
      console.log('----------监督程式:报告任务状态----------')
      thisObj.checkAndRunAliveTask()
      thisObj.supervisorExe()
    }, time * 1000)
  }
  /**
   * 检查所有激活但是超时未执行的程序并重新激活
   * 超时一个周期又10秒未执行的程序
   * 注意：本身处于关闭的任务不会在此激活
   */
  checkAndRunAliveTask() {
    let now = new Date().getTime()
    this._taskList.forEach(item => {
      item.showMessage()
      if (
        item.isAlive &&
        !item.isRestart &&
        now - item.lastAliveTime.getTime() - 10000 > item.sleepTime
      ) {
        item.restart()
      }
    })
  }

  /**
   * 立即运行所有任务
   */
  startAllTask() {
    this._taskList.forEach(item => {
      item.start()
    })
  }

  /**
   * 关停所有的任务
   */
  stopAllTask() {
    this._taskList.forEach(item => {
      item.isAlive = false
    })
  }

  /**
   * 实例销毁
   * 先关闭所有任务，丢弃任务。
   */
  destroy() {
    this._taskList.forEach(item => {
      item.isAlive = false
    })
    this._taskList = []
  }
}

class InterlvalTask {
  /**
   *
   * @param {*} task
   * @param {*} sleepTime
   * @param {*} taskDescribe
   */
  constructor(task, sleepTime, taskDescribe) {
    /**
     * isAlive:boolean 控制任务激活还是等待
     * isRestart:boolean 表示重启状态
     * version:number 记录当前任务的版本
     * lastAliveTime:object 最近一次执行时间
     * task:function 要执行的函数
     * sleepTime:number 最小执行间隔,单位ms
     * taskDescribe:string 该任务描述文字，uuid,
     */
    this.isAlive = false
    this.isRestart = false
    this.version = 0
    this.lastAliveTime = new Date()
    this.task = task
    this.sleepTime = sleepTime
    this.taskDescribe = taskDescribe
  }
  /**
   * 任务运行函数-当任务激活时递归
   * 注意:你不要显示调用该方法.
   * 条件：该实例处于激活状态，实例版本没有变化（重启或者重新start都会改变当前版本）。
   * 提示：防止之前提交的任务没执行，继而又意外重新提交了一次或多次任务。
   *      导致多任务一起排队执行同一部分数据，对于单线程的js来说，简单的控制足够了。
   */
  _run() {
    if (this.isAlive) {
      let thisObj = this
      this.lastAliveTime = new Date()
      this.task()
      setTimeout(
        function(version) {
          /**
           * 当任务正式执行时，检查该任务是否已经过时。
           */
          if (version < thisObj.version) {
            return
          }
          thisObj._run()
        },
        this.sleepTime,
        this.version
      )
    }
  }
  /**
   * 开启任务-防止重复开启
   * 当任务已经开启时，不在重复开启
   */
  start() {
    if (this.isAlive) {
      return
    } else {
      this.isAlive = true
      this.version++
      this._run()
    }
  }
  /**
   * 关闭任务-但不销毁实例。
   */
  stop() {
    console.log('----------定时任务-----------')
    console.log(this.taskDescribe + ':关闭')
    this.isAlive = false
  }
  /**
   * 重启任务,无论之前的状态是什么。
   * 先关闭任务，跳过一个任务周期，再启动。
   */
  restart() {
    if (this.isRestart) {
      return
    }
    console.log('----------定时任务-----------')
    console.log(this.taskDescribe + ':正在重新启动')
    console.log('需要时间:' + (this.sleepTime + 5000) + 'ms')
    let thisObj = this
    this.isAlive = false
    this.isRestart = true
    this.version++
    setTimeout(function() {
      thisObj.isAlive = true
      thisObj.isRestart = false
      thisObj._run()
    }, this.sleepTime + 5000)
  }
  /**
   * 获取这个任务的描述信息
   */
  showMessage() {
    console.log('----------定时任务:详细信息-----------')
    console.log('任务名称：' + this.taskDescribe)
    if (this.isAlive) {
      console.log('任务状态：这个任务正在进行')
    } else {
      console.log('任务状态：这个任务关闭了')
    }
    console.log('任务执行间隔ms：' + this.sleepTime)
    console.log('任务最近执行时间点：' + this.lastAliveTime.toLocaleString())
  }
}
