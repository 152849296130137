import axios from "./http-interceptoer";
export default {
  // 获取留样柜门号留样信息
  getCabinetDoor(canteenId) {
    return axios.get(
      `/api/client-manage/screen/reservedSample/cabinetDoor/${canteenId}`
    );
  },
  // 获取留样柜概览信息
  getCabinetOverview(canteenId) {
    return axios.get(
      `/api/client-manage/screen/reservedSample/cabinetOverview/${canteenId}`
    );
  },
  //websockt Fn
  getWebSocketApi(canteenId) {
    return axios.get("/api/client-manage/web-socket/getToken/" + canteenId);
  },

  //获取应采购与实际采购数量
  getListRequiredAndActualCount(canteenId, date) {
    return axios.get(
      `/api/client-manage/screen/listRequiredAndActualCount/${canteenId}?date=${date}`
    );
  },
  //获取行为违规集合
  getListBehaviorStandard(canteenId, count) {
    return axios.get(
      `/api/client-manage/screen/listBehaviorStandard/${canteenId}?count=${count}`
    );
  },
  //获取温湿度设备
  getListTempHumidityDevice(canteenId) {
    return axios.get(
      `/api/client-manage/screen/listTempHumidityDevice/${canteenId}`
    );
  },
  //获取当日菜品信息--废弃-来自大屏监控中兴
  getListDishesInfo(dayOfWeek) {
    return axios.get(
      `/api/client-manage/screen/listDishesInfo?dayOfWeek=${dayOfWeek}`
    );
  },
  //获取菜品消费明细--可能废弃-来自大屏监控中兴
  getListDishesSalesData() {
    return axios.get(`/api/client-manage/screen/listDishesSalesData`);
  },
  //获取实时消费动态--可能废弃-来自大屏监控中兴
  getListRealTime(count) {
    return axios.get(`/api/client-manage/screen/listRealTime?count=${count}`);
  },
  //查询异常考勤记录
  getlistAbnormal(canteenId, count) {
    return axios.get(
      `/api/client-manage/screen/listAbnormal/${canteenId}?count=${count}`
    );
  },
  //获取考勤记录
  getAttendanceList(params) {
    return axios.get(`/api/client-manage/clientAttendance/list/page`, {
      params
    });
  },
  // 留样应急开门处理
  handleSampleOpenRecord(params) {
    return axios.post("/api/client-manage/sampleOpenRecord/handle", params);
  },
  // 处理考勤异常
  dealAlarmEntrance(params) {
    return axios.post(
      "/api/client-manage/clientAttendance/handle/abnormal",
      params
    );
  }
};
