// rem自适应布局  兼容到ipad Pro宽1024px
;(function(designWidth, maxWidth) {
  var doc = document,
    win = window
  var docEl = doc.documentElement
  var tid
  var rootItem, rootStyle

  function refreshRem() {
    var width = window.window.innerWidth
    // var screen_flag = '';
    // if (window.innerWidth > window.innerHeight) {
    //     screen_flag = "heng";
    //     width = window.window.innerHeight;
    // } else {
    //     screen_flag = "shu";
    //     width = window.window.innerWidth;
    // }
    if (!maxWidth) {
      // maxWidth = 1920;
      // maxWidth = 2560;
    }
    if (width > maxWidth) {
      // width = 1920;
      // width = 2560;
    } else if (width < 1600) {
      // width = 1600;
    }
    //与淘宝做法不同，直接采用简单的rem换算方法1rem=100px
    var rem = parseInt((width * 100) / designWidth)
    //兼容UC开始
    rootStyle = 'html{font-size:' + rem + 'px !important}'
    rootItem = document.getElementById('rootsize') || document.createElement('style')
    if (!document.getElementById('rootsize')) {
      document.getElementsByTagName('head')[0].appendChild(rootItem)
      rootItem.id = 'rootsize'
    }
    if (rootItem.styleSheet) {
      rootItem.styleSheet.disabled || (rootItem.styleSheet.cssText = rootStyle)
    } else {
      try {
        rootItem.innerHTML = rootStyle
      } catch (f) {
        rootItem.innerText = rootStyle
      }
    }
    //兼容UC结束
    docEl.style.fontSize = rem + 'px'
  }
  refreshRem()

  win.addEventListener(
    'resize',
    function() {
      clearTimeout(tid) //防止执行两次
      tid = setTimeout(refreshRem, 300)
    },
    false
  )

  win.addEventListener(
    'pageshow',
    function(e) {
      if (e.persisted) {
        // 浏览器后退的时候重新计算
        clearTimeout(tid)
        tid = setTimeout(refreshRem, 300)
      }
    },
    false
  )

  if (doc.readyState === 'complete') {
    doc.body.style.fontSize = '16px'
  } else {
    doc.addEventListener(
      'DOMContentLoaded',
      function() {
        doc.body.style.fontSize = '16px'
      },
      false
    )
  }
})(1920, 1920)
;(function() {
  if (
    typeof window.WeixinJSBridge === 'object' &&
    typeof window.WeixinJSBridge.invoke === 'function'
  ) {
    handleFontSize()
  } else {
    if (document.addEventListener) {
      document.addEventListener('WeixinJSBridgeReady', handleFontSize, false)
    } else if (document.attachEvent) {
      document.attachEvent('WeixinJSBridgeReady', handleFontSize)
      document.attachEvent('onWeixinJSBridgeReady', handleFontSize)
    }
  }

  function handleFontSize() {
    // 设置网页字体为默认大小
    window.WeixinJSBridge.invoke('setFontSizeCallback', {
      fontSize: 0
    })
    // 重写设置网页字体大小的事件
    window.WeixinJSBridge.on('menu:setfont', function() {
      window.WeixinJSBridge.invoke('setFontSizeCallback', {
        fontSize: 0
      })
    })
  }
})()
