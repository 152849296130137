<template>
    <div class="hello">
        <!-- 大盒子 -->
        <div id="wrapper">
            <!-- 头部导航 -->
            <div id="header">
                <!-- 晴天 -->
                <div class="sunny_box">
                    <div class="sunny_image"></div>
                    <div class="sunny_text">
                        <p class="sunny_data">{{ weather1.date }}</p>
                        <p class="sunny_temperature">
                            {{ weather1.tem_night }}°~{{ weather1.tem_day }}°
                        </p>
                    </div>
                </div>

                <!-- 多云cloudy -->
                <div class="cloudy_box">
                    <div class="cloudy_image"></div>
                    <div class="cloudy_text">
                        <p class="cloudy_data">{{ weather2.date }}</p>
                        <p class="cloudy_temperature">
                            {{ weather2.tem_night }}°~{{ weather2.tem_day }}°
                        </p>
                    </div>
                </div>

                <!-- 多云thunder -->
                <div class="thunder_box">
                    <div class="thunder_image"></div>
                    <div class="thunder_text">
                        <p class="thunder_data">{{ weather3.date }}</p>
                        <p class="thunder_temperature">
                            {{ weather3.tem_night }}°~{{ weather3.tem_day }}°
                        </p>
                    </div>
                </div>

                <div class="city">
                    <span>{{ city }}</span>
                </div>

                <h2 class="title">智慧食堂实时运营状态</h2>

                <div class="time">
                    <p class="week">{{ timers }}{{ week }}</p>
                    <p class="now_time">{{ timer }}</p>
                </div>

                <div class="come_back">
                    <span class="span1">{{ canteenName }}</span>
                </div>

                <div class="exit"></div>
            </div>

            <div id="count">
                <!-- 留样 -->
                <div class="sample_box">
                    <p class="sample_title">今日留样概览</p>
                    <span class="sanjiao"></span>
                    <div class="sample_insid">
                        <ul class="sample_ul">
                            <li>
                                <span class="left">
                                    <strong class="fangkuai blue"></strong>
                                    临时出样时间：
                                </span>
                                <span class="center">{{ sampleData.nextSampleDoor }}号门</span>
                                <span class="right">
                                    {{ sampleData.nextSampleTimeString }}
                                </span>
                            </li>
                            <li>
                                <span class="left">
                                    <strong class="fangkuai orange"></strong>
                                    临时出样餐次：
                                </span>
                                <span class="center">
                                    {{ sampleData.nextReservedSampleMealName }}
                                </span>
                            </li>
                            <li class="today" style="margin-top: 0.1rem">
                                <span class="left1">
                                    <strong>今日留样进度:</strong>
                                    {{ sampleData.todayReservedSampleProcess }}
                                </span>
                                <span class="center1">
                                    <strong>应留样次数：</strong>
                                    {{ sampleData.totalSampleNum }}
                                </span>
                                <span class="right1">
                                    <strong>已留样次数：</strong>
                                    {{ sampleData.hasSampledNum }}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- 光环 -->
                <div class="halo"></div>
                <div class="kuang"></div>
                <div class="tu41"></div>
                <div class="qiye">
                    <p>企业名称： {{ canteenName }}</p>
                    <p>企业地址： {{ canteenInfo.detailedAddress }}</p>
                    <p style="text-indent: 0.7rem"></p>
                </div>
                <div class="xian"></div>

                <div class="statistics">
                    <p class="statistics_title">数据统计</p>
                    <ul>
                        <li class="statistics_li">
                            <div class="statistics_div">
                                <p class="statistics_num">
                                    <span>{{ personNumSum }}</span>
                                </p>
                                <!-- <p class="statistics_xq">今日应到岗员工</p> -->
                                <p class="statistics_xq">食堂员工人数</p>
                            </div>
                            <div class="statistics_div">
                                <p class="statistics_num">
                                    <span>{{ attendStaffNum }}</span>
                                </p>
                                <p class="statistics_xq">今日实际到岗员工</p>
                            </div>
                        </li>
                        <li class="statistics_li">
                            <div class="statistics_div">
                                <p class="statistics_num">
                                    <span>{{ menuNum }}</span>
                                </p>
                                <p class="statistics_xq">今日菜品数量</p>
                            </div>
                            <div class="statistics_div">
                                <p class="statistics_num">
                                    <span>0</span>
                                </p>
                                <p class="statistics_xq">店家数量</p>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="realtime">
                    <!--          <p class="realtime_title"></p>-->
                    <ul>
                        <div style="color: #00fffc">
                            <div id="box">
                                <div style="flex: 2">留样时间</div>
                                <div style="flex: 1.4">留样菜品</div>
                                <div style="flex: 1">留样克量</div>
                                <div style="flex: 1">留样人</div>
                            </div>
                            <hr color="#00fffc" />

                            <div class="containers">
                                <dv-scroll-board :config="config1" style="width:100%;height:100%" />
                            </div>
                        </div>
                    </ul>
                </div>

                <div class="publicity">
                    <p class="publicity_title">证件公示</p>
                    <ul id="s1" class="transport_ul">
                        <swiper :options="swiperOption" style="margin-bottom: 2%">
                            <swiper-slide
                                class="carousel-card"
                                v-for="(item, index) in optCertificateImgs"
                                :key="index"
                            >
                                <img :src="$imgPrefix + item" style="height: 100%;width: 100%;" />
                            </swiper-slide>
                        </swiper>

                        <!-- 自动播放 -->
                        <swiper :options="swiperOptions">
                            <swiper-slide
                                class="carousel-cards"
                                v-for="(item, index) in healthCertificateImgs"
                                :key="index"
                            >
                                <img :src="$imgPrefix + item" style="height:100%;width: 100%;" />
                            </swiper-slide>
                        </swiper>
                    </ul>
                </div>

                <div class="security">
                    <ul class="security_ul">
                        <li class="security_li">
                            <div class="security_box">
                                <p class="security_title">门禁人脸识别机：正常</p>
                                <p class="security_img green"></p>
                            </div>

                            <div class="security_box">
                                <p v-if="isOpen == true" class="security_title">
                                    可燃气体：正常
                                </p>
                                <p v-if="isOpen == true" class="security_img green"></p>
                                <p v-if="isOpen == false" class="security_title">
                                    可燃气体：异常
                                </p>
                                <p v-if="isOpen == false" class="security_img red"></p>
                            </div>
                        </li>
                        <li class="security_li">
                            <div class="security_box">
                                <p class="security_title">门禁考勤机：正常</p>
                                <p class="security_img green"></p>
                            </div>

                            <div
                                class="security_box"
                                style="line-height: 0.35rem; text-align: left"
                            >
                                <span v-if="isOpen" class="security_title">燃气阀门：开</span>
                                <span v-if="isOpen == false" class="security_title">
                                    燃气阀门：开
                                </span>
                            </div>
                        </li>
                    </ul>
                    <div class="Group_Famen"></div>
                    <div class="Group_27"></div>
                </div>

                <div class="transport">
                    <p class="transport_title"></p>
                    <ul id="s2" class="transport_ul">
                        <div style="color: #00fffc">
                            <div id="box">
                                <div style="flex: 1">姓名</div>
                                <div style="flex: 1.6">到岗时间(当日)</div>
                                <div style="flex: 1">体温(℃)</div>
                                <div style="flex: 1.6">健康证到期</div>
                            </div>
                            <hr color="#00fffc" />

                            <div class="container">
                                <dv-scroll-board :config="config2" style="width:100%;height:100%" />
                            </div>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BMap from 'BMap'
import axios from 'axios'
import api from '@/mock/services/datacenter'
import bigscreenService from '@/mock/services/bigscreen.js'
import adaptive from '@/assets/js/adaptive.js' // 请不要注释这个看似没用的代码，这是用来适配屏幕的
import IntervalManager from '@/assets/utils/IntervalManager'

export default {
    name: 'HelloWorld',
    props: {
        msg: String
    },
    data() {
        return {
            config1: {
                data: [],
                rowNum: 14,
                waitTime: 1000
            },
            config2: {
                data: [],
                rowNum: 8,
                waitTime: 1000
            },
            sampleInfo: [], //食堂留样信息
            swiperOptions1: {
                slidesPerView: 1,
                slidesPerColumn: 2, //显示2行
                spaceBetween: 10,
                slidesPerColumnFill: 'row',
                direction: 'vertical',
                loop: true
            },
            swiperOptions: {
                slidesPerView: 2, //一行显示3个
                slidesPerColumn: 1, //显示2行
                spaceBetween: 10,
                slidesPerColumnFill: 'row',
                autoplay: {
                    delay: 1000,
                    stopOnLastSlide: false,
                    disableOnInteraction: true
                }
            },
            swiperOption: {
                slidesPerView: 2,
                slidesPerColumn: 1, //显示2行
                spaceBetween: 10,
                slidesPerColumnFill: 'row',

                autoplay: {
                    delay: 4000,
                    stopOnLastSlide: false,
                    disableOnInteraction: true
                }
            },
            city: '',
            hasUpdateTodayData: false, // 标记今天是否初始化了数据

            //logo
            logoUrl: '',
            // 辅助数据组
            timer: ' -年-月-日 ',
            weather: [], //天气数组
            fen: [], //分割数组
            weather1: {
                date: '',
                tem_night: '',
                tem_day: ''
            }, //今天
            weather2: {
                date: '',
                tem_night: '',
                tem_day: ''
            }, //明天
            weather3: {
                date: '',
                tem_night: '',
                tem_day: ''
            }, //后天

            week: '',
            timers: '',
            canteenName: '', // 当前食堂名
            canteenInfo: '', //食堂详细信息
            canteenId: -1, // 当前食堂ID

            canteenPeopleInfo: [], //食堂人员信息
            // 亮证经营
            optCertificateImgs: [],
            // 健康证数据
            healthCertificateImgs: [],

            // 中心顶部状态
            attendStaffNum: 0, // 今日到岗员工
            personNumSum: 0, //今日排班员工
            menuNum: 0, // 今日菜品份数
            warningCounter: 0, // 今日预警计数器

            // 留样模块数据
            sampleData: {
                nextSampleDoor: 0, // 临近出样门编号
                nextSampleCountDown: 0, // 临近留样时间总秒-源数据
                timeInterval: 0, // 临近留样迭代器
                nextSampleTimeString: '00:00:00', // 格式化后的临近留样时间
                nextReservedSampleMealName: '无', // 临近出样餐次
                hasSampledNum: 0, // 已经留样餐次
                totalSampleNum: 0, // 今天总需要留言餐次
                todayReservedSampleProcess: '0%' // 今日留样进度
            },
            //阀门
            isOpen: true
        }
    },
    activated() {
        // 进入页面-开启所有定时任务
        this.intervalManager.startAllTask()
    },
    destroyed() {
        // 离开页面-关闭所有定时任务
        this.intervalManager.stopAllTask()
    },
    mounted() {
        if (!Array.prototype.flat) {
            Array.prototype.flat = function(count) {
                let c = count || 1
                let len = this.length
                let exe = []
                if (this.length == 0) return this
                while (c--) {
                    let _arr = []
                    let flag = false
                    if (exe.length == 0) {
                        flag = true
                        for (let i = 0; i < len; i++) {
                            if (this[i] instanceof Array) {
                                exe.push(...this[i])
                            } else {
                                exe.push(this[i])
                            }
                        }
                    } else {
                        for (let i = 0; i < exe.length; i++) {
                            if (exe[i] instanceof Array) {
                                flag = true
                                _arr.push(...exe[i])
                            } else {
                                _arr.push(exe[i])
                            }
                        }
                        exe = _arr
                    }
                    if (!flag && c == Infinity) {
                        break
                    }
                }
                return exe
            }
        }

        let thisPage = this
        this.canteenName = this.$route.query.canteenName
        this.canteenId = this.$route.query.canteenId
        this.getCanteenDetail(this.canteenId)
        this.intervalManager = new IntervalManager('DataCenter定时任务管理器')
        //获取当地城市及天气
        this.getCity()
        // 获取食堂经营证
        this.getCanteenPic()
        // 获取食堂留样信息
        this.getSampleInfo(this.canteenId)
        //获取食堂人员信息
        this.getCanteenPeopleInfo(this.canteenId)

        // 获取食堂留样信息
        this.getSampleInfo(this.canteenId)

        // 获取食堂员工照片
        this.getUserHealthCardPic()

        // 食堂其他实时运营数据
        this.refreshCanteenRealTimeData(this.canteenId)

        // 今日留样概览
        this.refreshSampleStateData(this.canteenId)

        // 时间显示定时器
        this.intervalManager.addTask(
            function() {
                let timer = window.moment().format('YYYY-MM-DD HH:mm:ss d')
                let weekNum = Number(timer.charAt(timer.length - 1))
                let weekString = ''
                switch (weekNum) {
                    case 0:
                        weekString = '星期日'
                        break
                    case 1:
                        weekString = '星期一'
                        break
                    case 2:
                        weekString = '星期二'
                        break
                    case 3:
                        weekString = '星期三'
                        break
                    case 4:
                        weekString = '星期四'
                        break
                    case 5:
                        weekString = '星期五'
                        break
                    case 6:
                        weekString = '星期六'
                        break
                }
                thisPage.timer = timer.substring(10, 19)
                thisPage.timers = timer.substring(5, 11)
                thisPage.week = weekString
            },
            1000,
            '定时器任务'
        )
        // 定时任务
        this.intervalManager.addTask(this.refreshData, 600000, '定时任务组')
    },
    components: {},
    methods: {
        //获取食堂留样信息
        getSampleInfo(canteenId) {
            api.getSampleInfo(canteenId).then(res => {
                if (res && res.success && res.results) {
                    this.sampleInfo = res.results
                    this.sampleInfo.forEach(item => {
                        this.fen = item.barCode.split('|')
                        Object.assign(item, { name: this.fen[6] })
                    })
                    //封装dataV组件需要的数据
                    this.config1.data = []
                    this.sampleInfo.forEach(item => {
                        let temp = [
                            '<span style="color:#00fffc;font-size: 0.16rem;">' +
                                item.createdTime +
                                '</span>',
                            '<span style="color:#00fffc;font-size: 0.16rem;">' +
                                item.dishesName +
                                '</span>',
                            '<span style="color:#00fffc;font-size: 0.16rem;">' +
                                item.sampleWeight +
                                '克' +
                                '</span>',
                            '<span style="color:#00fffc;font-size: 0.16rem;">' +
                                item.name +
                                '</span>'
                        ]
                        this.config1.data.push(temp)
                        this.config1 = { ...this.config1 }
                    })
                }
            })
        },
        //获取食堂人民信息
        getCanteenPeopleInfo(canteenId) {
            api.getCanteenPeopleInfo(Number(canteenId)).then(res => {
                if (res && res.success && res.results) {
                    this.canteenPeopleInfo = res.results
                    this.canteenPeopleInfo.forEach(item => {
                        if (item.todayArriveTime == null) {
                            item.todayArriveTime = '无'
                        } else {
                            var tiem2 = item.todayArriveTime.substring(11, 20)
                            item.todayArriveTime = tiem2
                        }
                        if (item.temperature == null) {
                            item.temperature = '无'
                        }
                    })
                    //封装dataV组件需要的数据
                    this.config2.data = []
                    this.canteenPeopleInfo.forEach(item => {
                        let temp = [
                            '<span style="color:#00fffc;font-size: 0.16rem;">' +
                                item.staffName +
                                '</span>',
                            '<span style="color:#00fffc;font-size: 0.16rem;">' +
                                item.todayArriveTime +
                                '</span>',
                            '<span style="color:#00fffc;font-size: 0.16rem;">' +
                                item.temperature +
                                '</span>',
                            '<span style="color:#00fffc;font-size: 0.16rem;">' +
                                item.healthCardEffectiveDate +
                                '</span>'
                        ]
                        this.config2.data.push(temp)
                        this.config2 = { ...this.config2 }
                    })
                }
            })
        },
        //获取食堂详细信息
        getCanteenDetail(id) {
            api.getCanteenDetail(id).then(res => {
                if (res && res.success && res.results) {
                    this.canteenInfo = res.results
                }
            })
        },
        //获取当地城市
        getCity() {
            const geolocation = new BMap.Geolocation()
            var _this = this
            geolocation.getCurrentPosition(function getinfo(position) {
                let city = position.address.city
                city = city.substring(0, city.length - 1)
                _this.city = city
                _this.getWeather()
            })
        },
        //获取当地天气
        getWeather() {
            axios({
                methods: 'get',
                url: 'https://v0.yiketianqi.com/free/week',
                headers: {},
                params: {
                    appid: '65235157', // 账号ID
                    appsecret: 'n4HAgVpx', // 账号密钥
                    city: this.city, // 城市名称,不要带市和区
                    callback: 0 // jsonp方式
                }
            }).then(res => {
                this.weather = res.data.data
                this.weather1.date = this.weather[0].date.substring(5, 10)
                this.weather1.tem_night = this.weather[0].tem_night
                this.weather1.tem_day = this.weather[0].tem_day

                this.weather2.date = this.weather[1].date.substring(5, 10)
                this.weather2.tem_night = this.weather[1].tem_night
                this.weather2.tem_day = this.weather[1].tem_day

                this.weather3.date = this.weather[2].date.substring(5, 10)
                this.weather3.tem_night = this.weather[2].tem_night
                this.weather3.tem_day = this.weather[2].tem_day
            })
        },

        // 定时任务
        refreshData() {
            this.refreshCanteenRealTimeData(this.canteenId)
            this.refreshSampleStateData(this.canteenId)
            this.getSampleInfo(this.canteenId)
            this.getCanteenPeopleInfo(this.canteenId)
        },
        // 获取食堂经营证检照
        getCanteenPic() {
            let thisPage = this
            api.getCanteenPic(this.canteenId).then(res => {
                if (res && res.success && res.results) {
                    let tempOptCertificateImgs = []
                    let counter = 0
                    let imgsObj = res.results
                    thisPage.logoUrl = imgsObj.logoUrl
                    for (let key in imgsObj) {
                        if (
                            key === 'logoUrl' ||
                            key === 'sceneUrl' ||
                            key === 'inspectionResultsUrl'
                        ) {
                            continue
                        }
                        if (
                            imgsObj[key] == '' ||
                            imgsObj[key] == undefined ||
                            imgsObj[key] == null
                        ) {
                            continue
                        }
                        if (counter % 2 == 0) {
                            let newArray = []
                            newArray.push(imgsObj[key])
                            tempOptCertificateImgs.push(newArray)
                        } else {
                            tempOptCertificateImgs[tempOptCertificateImgs.length - 1].push(
                                imgsObj[key]
                            )
                        }
                        counter++
                    }
                    thisPage.optCertificateImgs = tempOptCertificateImgs.flat(Infinity)
                }
            })
        },
        //  获取食堂员工的健康证照片
        getUserHealthCardPic() {
            let thisPage = this
            api.getUserHealthCardPic(this.canteenId).then(res => {
                if (res && res.success && res.results) {
                    let tempHealthCertificateImgs = []
                    let counter = 0
                    let imgsObj = res.results
                    for (let key in imgsObj) {
                        if (counter % 4 == 0) {
                            let newArray = []
                            newArray.push(imgsObj[key])
                            tempHealthCertificateImgs.push(newArray)
                        } else {
                            tempHealthCertificateImgs[tempHealthCertificateImgs.length - 1].push(
                                imgsObj[key]
                            )
                        }
                        counter++
                    }
                    thisPage.healthCertificateImgs = tempHealthCertificateImgs.flat(Infinity)
                }
            })
        },
        // 获取食堂其他运行状态数据
        refreshCanteenRealTimeData(canteenId) {
            if (!canteenId || canteenId < 1) {
                return
            }
            api.getRealTimeDataForCanteen(canteenId).then(res => {
                if (res && res.success) {
                    this.attendStaffNum = res.results.attPersonNumSum
                        ? res.results.attPersonNumSum
                        : 0
                    this.menuNum = res.results.sampleRecordNumSum
                        ? res.results.sampleRecordNumSum
                        : 0
                    this.personNumSum = res.results.personNumSum ? res.results.personNumSum : 0
                }
            })
        },
        // 获取留样模块数据
        refreshSampleStateData(canteenId) {
            if (!canteenId || canteenId < 1) {
                return
            }
            let thisPage = this
            bigscreenService.getCabinetOverview(canteenId).then(res => {
                if (res && res.success && res.results) {
                    thisPage.sampleData.nextSampleDoor = res.results.nextSampleDoor
                    thisPage.sampleData.nextSampleCountDown = res.results.nextSampleCountDown
                    thisPage.sampleData.nextReservedSampleMealName =
                        res.results.nextReservedSampleMealName
                    thisPage.sampleData.todayReservedSampleProcess =
                        res.results.todayReservedSampleProcess
                    thisPage.sampleData.hasSampledNum = res.results.haveSampleCount
                    thisPage.sampleData.totalSampleNum = res.results.needSampleCount
                    if (thisPage.sampleData.totalSampleNum > 0) {
                        let tempNum =
                            thisPage.sampleData.hasSampledNum / thisPage.sampleData.totalSampleNum
                        thisPage.sampleData.todayReservedSampleProcess =
                            Number.parseInt(tempNum * 100) + '%'
                    }
                    if (thisPage.sampleData.nextSampleCountDown <= 0) {
                        return
                    }
                    let nowTime = new Date().getTime()
                    let endTime = nowTime + thisPage.sampleData.nextSampleCountDown * 1000
                    thisPage.sampleData.timeInterval = thisPage.intervalManager.addTask(
                        function() {
                            thisPage.countDown((endTime - new Date().getTime()) / 1000)
                        },
                        1000,
                        '出样倒计时任务'
                    )
                    thisPage.intervalManager.startTaskByIndex(thisPage.sampleData.timeInterval)
                }
            })
        },

        // 倒计时格式化
        countDown(time) {
            if (time >= 0) {
                this.hr = parseInt((time / 3600) % 24)
                this.min = parseInt((time / 60) % 60)
                this.sec = parseInt(time % 60)
                this.hr = this.hr < 10 ? '0' + this.hr : this.hr
                this.min = this.min < 10 ? '0' + this.min : this.min
                this.sec = this.sec < 10 ? '0' + this.sec : this.sec
                this.sampleData.nextSampleTimeString = this.hr + ':' + this.min + ':' + this.sec
            } else {
                this.intervalManager.stopTaskByIndex(this.sampleData.timeInterval)
            }
        }
    }
}
</script>

<style lang="less" scoped>
@import '../../../assets/css/initialization.css';
@import '../../../assets/css/index.css';

.container {
    position: relative;
    /* 父容器需要有明确的高度 */
    //height: 260 / @vh;
    height: 2.6rem;
    width: 100%;
    margin: auto;
    margin-top: 3%;
    overflow: hidden;
}

.containers {
    position: relative;
    /* 父容器需要有明确的高度 */
    //height: 490 / @vh;
    height: 4.9rem;
    width: 100%;
    margin: auto;
    margin-top: 3%;
    overflow: hidden;
}

.containers > .scroll-list {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    //animation: scroll 9s linear infinite normal;
}

.containers > .scroll-list > div {
    width: 100%;
    /* 滚动的项目需要有具体的高度 */
    height: 30px;
    display: flex;
    align-items: center;
    color: #00fffc;
}

.containers > .scroll-list > div:nth-child(2n) {
}

.container > .scroll-list {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    //animation: scroll 9s linear infinite normal;
    animation: scroll 12s linear infinite normal;
}

.container > .scroll-list > div {
    width: 100%;
    /* 滚动的项目需要有具体的高度 */
    height: 30px;
    display: flex;
    align-items: center;
    color: #00fffc;
}

.container > .scroll-list > div:nth-child(2n) {
}

@keyframes scroll {
    100% {
        /* 需要滚动内容的总高度 */
        top: -1000px;
    }
}

#box {
    display: flex;
    font-size: 0.18rem;
}

#box1 {
    display: flex;
}

.carousel-card {
    //height: 250 / @vh;
    height: 2.5rem;

    .greey-card {
    }
}

.carousel-cards {
    //height: 175 / @vh;
    height: 1.75rem;

    .greey-card {
        background: rgb(221, 221, 221);
    }
}

::v-deep .realtime .dv-scroll-board .rows .row-item {
    display: flex;
}

::v-deep .realtime .dv-scroll-board .rows .ceil:nth-child(4n + 1) {
    flex: 2;
    //width: 200px !important;
}

::v-deep .realtime .dv-scroll-board .rows .ceil:nth-child(4n + 2) {
    flex: 1.4;
    //width: 200px !important;
}
::v-deep .realtime .dv-scroll-board .rows .ceil:nth-child(4n + 3) {
    flex: 1;
    //width: 200px !important;
}
::v-deep .realtime .dv-scroll-board .rows .ceil:nth-child(4n + 4) {
    flex: 1;
    //width: 200px !important;
}

::v-deep .transport .dv-scroll-board .rows .row-item {
    display: flex;
}
::v-deep .transport .dv-scroll-board .rows .ceil:nth-child(4n + 1) {
    flex: 1;
    //width: 200px !important;
}

::v-deep .transport .dv-scroll-board .rows .ceil:nth-child(4n + 2) {
    flex: 1.6;
    //width: 200px !important;
}
::v-deep .transport .dv-scroll-board .rows .ceil:nth-child(4n + 3) {
    flex: 1;
    //width: 200px !important;
}
::v-deep .transport .dv-scroll-board .rows .ceil:nth-child(4n + 4) {
    flex: 1.6;
    //width: 200px !important;
}
</style>
